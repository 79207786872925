import default_page_type_translations from "./empty_objects/default_page_type_translations"
import default_page_type_translations_cz from "./empty_objects/default_page_type_translations_cz"
import default_translations_object from "./empty_objects/default_translations_object"
import default_translations_object_cz from "./empty_objects/default_translations_object_cz"

// Shared
export const shopify = "shopify"
export const ulsemo = "ulsemo"
export const imported = "imported"
export const created = "created"
export const updated = "updated"
export const deleted = "deleted"
export const added = "added"
export const removed = "removed"
export const TOGGLE_ELEMENTS = "TOGGLE_ELEMENTS"
export const SWITCH_PROJECT = "SWITCH_PROJECT"
export const SHOW_QUICK_LINKS = "SHOW_QUICK_LINKS"

// Routes
export const menu = "menu"
export const footer = "footer"
export const collection_banner = "collection_banner"
export const special_message_banner = "special_message_banner"
export const popular_products = "popular_products"
export const highlighted_products = "highlighted_products"
export const story_banner = "story_banner"
export const important_message_bar = "important_message_bar"
export const translations = "translations"
export const legal_translations = "legal_translations"
export const miscrosite_translations = "miscrosite_translations"
export const style_config = "style_config"
export const splash_image_config = "splash_image_config"
export const logo_upload = "logo_upload"
export const orders = "orders"
export const shipping = "shipping"
export const rest = "rest"
export const project = "project"
export const discounts = "discounts"
export const payment_gateway = "payment_gateway"
export const about_page = "about_page"
export const accounts = "accounts"
export const posts = "posts"
export const analytics = "analytics"
export const marketing_analytics = "marketing_analytics"
export const advertising = "advertising"
export const inventory = "inventory"

export const collection_sources = {
  shopify,
  imported
}

// Defaults
export const default_option_title = "Title" // This is what Shopify uses
export const default_option_name = "Default Title" // This is what Shopify uses as a products "option1" value when it has only one variant
export const default_product_name = "Product Title"
export const default_product_handle = "default-title"
export const default_menu_item_name = "Default name"
export const default_currency = "USD"

// Store names
export const CONTENT = "Content"
export const CONTENT_STORE = `Admin/${CONTENT}`
export const PRODUCTS = "Products"
export const PRODUCTS_STORE = `Admin/${PRODUCTS}`
export const CONFIGURATION = "Configuration"
export const CONFIGURATION_STORE = `Admin/${CONFIGURATION}`
export const FINANCE = "Finance"
export const FINANCE_STORE = `Admin/${FINANCE}`
export const USER = "User"
export const USER_STORE = `Admin/${USER}`
export const SOCIAL = "Social"
export const SOCIAL_STORE = `Admin/${SOCIAL}`
export const ADMIN = "Admin"
export const ADMIN_STORE = "Admin"
export const SHARED_STORE = "Shared"

// Route names
export const USER_ROUTE = "User"

// Error bar and Notifications severities
export const severities = {
  DANGER: "DANGER",
  WARNING: "WARNING",
  SUCCESS: "SUCCESS",
  NORMAL: "NORMAL"
}

// Maximum allowed accounts per tier type
export const account_amounts_per_plan_type = {
  basic: 1,
  intermediate: 5,
  full: 100,
}

export const min_password_length = 6

// Settings for email action link
export const action_code_settings = is_admin => ({
  // URL must be whitelisted in the Firebase Console.
  url: process.env.NODE_ENV === "development" ?
    `http://${is_admin ? "admin" : "marketplace" }.ulsemo.localhost:${is_admin ? "8081" : "8082" }` :
    `https://${is_admin ? "admin" : "marketplace" }.ulsemo.com/`,
  // This must be true.
  handleCodeInApp: true,
});

export const maximum_API_request_size = 10000000 // 10mb

export const paypal_connection_error_message = "client-id not recognized"

export const empty_user_permissions = {
  admin: false,
  products: {
    module: true,
    manage: true
  },
  layout_and_content: {
    module: true,
    manage: true
  },
  configuration: {
    module: true,
    sections: {
      translations: {
        module: true,
        manage: true
      },
      styling: {
        module: true,
        manage: true
      },
      shopify: {
        module: true,
        manage: true
      },
      shipping: {
        module: true,
        manage: true
      },
      payment_gateway: {
        module: true,
        manage: true
      },
      project_configuration: {
        module: true,
        manage: true
      },
      other_configuration: {
        module: true,
        manage: true
      }
    }
  },
  finance: {
    module: true,
    sections: {
      orders: {
        module: true,
        manage: true
      },
      discounts: {
        module: true,
        manage: true
      },
      analytics: {
        module: true,
        manage: true
      }
    }
  },
  social: {
    module: true,
    sections: {
      accounts: {
        module: true,
        manage: true,
      },
      posts: {
        module: true,
        manage: true,
      },
    },
  },
  user: {
    sections: {
      permissions: {
        module: true,
        manage: true
      },
      settings: {
        module: true,
        email: true,
        password: true
      }
    }
  }
}

// Global site translations
export const translation_objects = {
  en: default_translations_object,
  cz: default_translations_object_cz
}

// Single and couple page translations
export const page_type_translation_objects = {
  en: default_page_type_translations,
  cz: default_page_type_translations_cz
}

export const CHARTJS_DOWNLOAD_URL = "https://cdn.jsdelivr.net/npm/chart.js"

// Styles
export const desktop_width = 1080
export const tablet_width_small = 640


export const updatable_styles = [
  "font_light",
  "font_heavy",
  "background_light",
  "background_heavy",
  "body_color",
  "item_name_color"
]

export const default_color_object = {
  hex: "#000000",
  hsl: { h: 0, s: 0, l: 0, a: 1 },
  hsv: { h: 0, s: 0, v: 0, a: 1 },
  rgba: { r: 0, g: 0, b: 0, a: 1 },
  a: 1
}
