import Vue from "vue"
import Router from "vue-router"
import Content_routes, { client_products_routes } from "./data/routes/content-routes"
import Default_routes, { client_default_routes } from "./data/routes/default-routes"
import Admin_routes from "./data/routes/admin-routes"
import Marketplace_routes from "./data/routes/marketplace-routes"
import check_if_user_in_admin from "./applications/Shared/methods/check_if_user_in_admin"
import check_if_user_in_marketplace from "./applications/Shared/methods/check_if_user_in_marketplace"
import { AUTHORIZATION } from "./data/page_types"
import is_user_in_global_admin from "./applications/Shared/methods/is_user_in_global_admin"
import store, { SAVE_LAST_VISITED_PAGE } from "./store"
import { ADMIN, TOGGLE_ELEMENTS, USER } from "./applications/Admin/constants/others_constants"
import { event_hub } from "./main"
import check_if_user_in_store_preview from "./applications/Shared/methods/check_if_user_in_store_preview"
import { SHARED_STORE } from "./applications/Shared/constants/other"
import { CLIENT_STORE } from "./applications/Client/constants/other"
import { MULTI } from "./data/product_variety_types"
import { get_ga_instance } from "./google_tracking"

const Store_preview = () => import("./applications/Client/components/base/Store_preview.vue")

Vue.use(Router)

const is_global_admin = is_user_in_global_admin()
const originalPush = Router.prototype.push;

Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(_ => {});
}

const store_routes = [
  {
    path: check_if_user_in_store_preview() ? "/:store_id?" : "",
    component: Store_preview,
    children: [
      ...Content_routes,
      ...Default_routes,
    ]
  },
]
const client_route_names = [...Object.values(client_products_routes), ...Object.values(client_default_routes)]
let final_routes = []

if (check_if_user_in_admin()) final_routes = Admin_routes
else if (check_if_user_in_marketplace()) final_routes = Marketplace_routes
else final_routes = store_routes

const before_each_method = (to, from, next) => {
  if (!client_route_names.includes(to.name)) event_hub.$emit(TOGGLE_ELEMENTS)

  store.commit(SAVE_LAST_VISITED_PAGE, from, { root: true })

  // Re-attach query params if couple or single product page
  if (
    !to.query.product &&
    !to.query.collection &&
    store.state[CLIENT_STORE].category_of_product_variety !== MULTI
  ) next({ path: to.path, query: from.query })

  if (
    check_if_user_in_store_preview() &&
    store.state[SHARED_STORE].store_id &&
    !to.path.includes(store.state[SHARED_STORE].store_id)
  ) {
    const path = String(to.fullPath).replace(`${store.state[SHARED_STORE].store_id}`, "")

    return next({ path: `/${store.state[SHARED_STORE].store_id}${path}`, query: to.query })
  }

  if (to.hash && !client_route_names.includes(to.name)) next({ path: to.path })
  else if (
    is_global_admin &&
    store.state[ADMIN][USER].selected_project &&
    !to.fullPath.includes(store.state[ADMIN][USER].selected_project) &&
    !client_route_names.includes(to.name)
  ) {
    const path = String(to.fullPath).toLowerCase().replace("/admin", "")

    next({ path: `/admin/${store.state[ADMIN][USER].selected_project}${path}` })
  }
  else next()
}

const after_each_method = async (to) => {
  if (client_route_names.includes(to.name)) {
    const instance = await get_ga_instance()

    instance?.page()
  }
}

export default (admin_imported = false) => {
  const router = new Router({
    routes: [
      ...(admin_imported ? store_routes : final_routes),
      {
        path: "*",
        redirect: "/"
      },
      {
        path: `/${AUTHORIZATION}`,
      }
    ],
    mode: admin_imported ? "hash" : "history",
  })

  router.beforeEach(before_each_method)
  if (!admin_imported) router.afterEach(after_each_method)

  return router
}
