import project_styles from "../../../../data/project_styles"
import is_user_in_global_admin from "../../methods/is_user_in_global_admin"
import check_if_user_in_admin from "../../methods/check_if_user_in_admin"
import check_if_mobile_device from "../../../Client/methods/check_if_mobile_device"
import check_if_touch_device from "../../../Client/methods/check_if_touch_device"

// mutations
export const FIRST_LOAD = "FIRST_LOAD"
export const UPDATE_MESSAGE = "UPDATE_MESSAGE"
export const UPDATE_USER_CREDENTIALS = "UPDATE_USER_CREDENTIALS"
export const UPDATE_MOBILE_VIEW = "UPDATE_MOBILE_VIEW"
export const UPDATE_DISCOUNT_CODE = "UPDATE_DISCOUNT_CODE"
export const TOGGLE_PREVIEW_BASKET = "TOGGLE_PREVIEW_BASKET"
export const CHANGE_QUANTITY_OF_PRODUCT = "CHANGE_QUANTITY_OF_PRODUCT"
export const UPDATE_BASKET_ITEMS = "UPDATE_BASKET_ITEMS"
export const SET_WHOLE_BASKET = "SET_WHOLE_BASKET"
export const ADD_BASKET_ITEM = "ADD_BASKET_ITEM"
export const DELETE_BASKET_ITEM = "DELETE_BASKET_ITEM"
export const UPDATE_BASKET_LOADER = "UPDATE_BASKET_LOADER"
export const CHANGE_ADJUSTED_STATE_OF_PRODUCT = "CHANGE_ADJUSTED_STATE_OF_PRODUCT"
export const REMOVE_PURCHASE_ITEM_FROM_BASKET = "REMOVE_PURCHASE_ITEM_FROM_BASKET"
export const UPDATE_MOUSE_OVER_BASKET = "UPDATE_MOUSE_OVER_BASKET"
export const UPDATE_CURRENCY_RATES = "UPDATE_CURRENCY_RATES"
export const UPDATE_BASKET_GROUPS = "UPDATE_BASKET_GROUPS"
export const UPDATE_SELECTED_BASKET_GROUP = "UPDATE_SELECTED_BASKET_GROUP"
export const UPDATE_ORDER_LINKS = "UPDATE_ORDER_LINKS"
export const CLEAR_ORDER_LINKS = "CLEAR_ORDER_LINKS"
export const SET_STORE_ID = "SET_STORE_ID"

// actions
export const ADMIN_LOGIN = "ADMIN_LOGIN"
export const CLIENT_LOGIN = "CLIENT_LOGIN"
export const LOG_OUT_USER = "LOG_OUT_USER"
export const VERIFY_AND_SET_DISCOUNT = "VERIFY_AND_SET_DISCOUNT"
export const VERIFY_LOGGED_USER = "VERIFY_LOGGED_USER"
export const GET_CURRENCY_RATES = "GET_CURRENCY_RATES"
export const VALIDATE_BASKET = "VALIDATE_BASKET"

// Getters
export const GET_PRODUCT_PRICE = "GET_PRODUCT_PRICE"
export const GET_BASKET_PRICE = "GET_BASKET_PRICE"
export const GET_TOTAL_PRICE = "GET_TOTAL_PRICE"
export const DOES_BASKET_REQUIRE_SHIPPING = "DOES_BASKET_REQUIRE_SHIPPING"
export const GET_TOTAL_PRODUCT_DISCOUNTS = "GET_TOTAL_PRODUCT_DISCOUNTS"


export const initial_state = {
  is_mobile_resolution: window.innerWidth <= project_styles.sizes.mobile,
  is_touch_device: check_if_touch_device(),
  is_mobile_device:
    check_if_mobile_device() ||
    check_if_user_in_admin(), // Setting to true in admin to shown correct styles in web preview
  first_load: true,
  show_preview_basket: false,
  basket_loader: false,
  mouse_over_basket: false,
  error_message: "",
  success_message: "",
  store_id: "",
  user_credentials: {},
  discount_code: {},
  currency_rates: {},
  basket_product_groups: {},
  basket_items: [],
  order_links: [],
  selected_group: null,
  is_global_admin: is_user_in_global_admin(),
}
