import { user_admin_store_id, user_basket_items } from "../../../../data/other_constants"
import { UPDATE_GLOBAL_LOADER, UPDATE_GLOBAL_USER } from "../../../../store"
import { create_new_user, get_user_projects, login_to_admin } from "../../../Admin/constants/endpoints/firebase"
import { USER, USER_STORE, ADMIN, action_code_settings } from "../../../Admin/constants/others_constants"
import {
  RESET_USER_STORE, SET_MANAGED_USERS, SET_PERMISSIONS, SET_USER_INFO,
  SET_USER_PROJECTS, SET_USER_SELECTED_PROJECT, SET_GENERAL_ADMIN_STATE
} from "../../../Admin/stores/Admin/user/constants"
import { CLIENT_STORE } from "../../../Client/constants/other"
import adjust_basket_data_to_not_include_duplicates from "../../../Client/methods/adjust_basket_data_to_not_include_duplicates"
import validate_basket from "../../../Client/methods/validate_basket"
import shopify_product_availability_updater from "../../../Client/methods/shopify_product_availability_updater"
import { social_logins, user_cookie } from "../../constants/other"
import { fetch_user_profile, verify_discount_code } from "../../methods/ajax/endpoints"
import {
  ADMIN_LOGIN, CLIENT_LOGIN, UPDATE_MESSAGE, UPDATE_USER_CREDENTIALS,
  LOG_OUT_USER, VERIFY_AND_SET_DISCOUNT, UPDATE_DISCOUNT_CODE,
  CHANGE_QUANTITY_OF_PRODUCT, SET_WHOLE_BASKET, UPDATE_BASKET_LOADER, VERIFY_LOGGED_USER, DELETE_BASKET_ITEM,
  UPDATE_CURRENCY_RATES, GET_CURRENCY_RATES, VALIDATE_BASKET
} from "./constants"
import { get_rates_for_currency } from "../../../Admin/constants/endpoints/other";
import { routerInstance } from "../../../../main"

const rates_being_fetched = {}

export default {
  async [VERIFY_LOGGED_USER]({ commit, state, dispatch, rootState }, { user_info, $router }) {
    if (state.is_global_admin) {
      if (!rootState[ADMIN][USER].selected_project) {
        const { data: user_projects } = await get_user_projects({ user_id: user_info.id })
        commit(`${USER_STORE}/${SET_USER_PROJECTS}`, user_projects, { root: true })
      }
    } else {
      commit(`${USER_STORE}/${SET_PERMISSIONS}`, user_info.permissions, { root: true })

      if (!rootState.user) {
        await dispatch(CLIENT_LOGIN, { email: user_info.email, is_registration: false, token: user_info.token, $router })
      }
    }

    commit(`${USER_STORE}/${SET_USER_INFO}`, user_info, { root: true })
  },
  async [ADMIN_LOGIN]({ commit, state, rootState }, {
    project_id, email, name, is_registration, password, password_again, success_message, language, country
  }, start_loader = true) {
    if (start_loader) commit(UPDATE_GLOBAL_LOADER, true, { root: true })
    let provider, result
    commit(UPDATE_MESSAGE, { type: "error", message: "" })

    if (name === social_logins[0]) provider = new window.firebase.auth.GoogleAuthProvider();
    else if (name === social_logins[1]) provider = new window.firebase.auth.FacebookAuthProvider();

    try {
      if (is_registration && !name) {
        if (password_again !== password) {
          if (start_loader) commit(UPDATE_GLOBAL_LOADER, false, { root: true })
          return commit(UPDATE_MESSAGE, { type: "error", message: "Passwords do not match." })
        }

        result = await window.firebase.auth().createUserWithEmailAndPassword(email, password)

        await create_new_user({
          user_data: {
            user_info: {
              name: result.user.displayName,
              email: result.user.email,
              country,
              language_code: language,
              phone_number: result.user.phoneNumber,
              uid: result.user.uid,
            }
          },
          is_basic_user: true
        })

        await window.firebase
          .auth()
          .currentUser
          .sendEmailVerification(action_code_settings(state.is_global_admin))

        if (start_loader) commit(UPDATE_GLOBAL_LOADER, false, { root: true })
        return commit(UPDATE_MESSAGE, {
          type: "success",
          message: success_message
        })
      }
      else if (name) {
        result = await window.firebase.auth().signInWithPopup(provider)

        if (result.additionalUserInfo.isNewUser) {
          await create_new_user({
            user_data: {
              user_info: {
                name: result.user.displayName,
                email: result.user.email,
                country,
                language_code: language,
                phone_number: result.user.phoneNumber,
                uid: result.user.uid,
              }
            },
            is_basic_user: true
          })
        }
      }

      if (!is_registration || name) {
        const user_credential_data = (project_id ? state.user_credentials : { email, password }) || {}
        const { data: {
          permissions,
          managed_users,
          user_info,
          error,
          is_any_store_admin,
          is_any_other_store_admin
        } } = await login_to_admin(
          result ? result.user.email : user_credential_data.email,
          user_credential_data.password,
          project_id,
          result ? result.user.uid : rootState[`${ADMIN}`][`${USER}`].user_info ? rootState[`${ADMIN}`][`${USER}`].user_info.id : null
        )
  
        if (error) commit(UPDATE_MESSAGE, { type: "error", message: error.message })
        else {
          if (!project_id && !name) commit(UPDATE_USER_CREDENTIALS, { email, password })
  
          if (project_id) commit(`${USER_STORE}/${SET_USER_SELECTED_PROJECT}`, project_id, { root: true })
          if (state.is_global_admin && !rootState[`${ADMIN}`][`${USER}`].selected_project) {
            const { data: user_projects } = await get_user_projects({ user_id: user_info.id })
  
            commit(`${USER_STORE}/${SET_USER_PROJECTS}`, user_projects, { root: true })
          } else {
            commit(`${USER_STORE}/${SET_PERMISSIONS}`, permissions, { root: true })
            if (managed_users) commit(`${USER_STORE}/${SET_MANAGED_USERS}`, managed_users, { root: true })
          }
  
          commit(`${USER_STORE}/${SET_GENERAL_ADMIN_STATE}`, { is_any_other_store_admin, is_any_store_admin }, { root: true })
          commit(`${USER_STORE}/${SET_USER_INFO}`, user_info, { root: true })
          if (project_id) window.localStorage.setItem(user_admin_store_id, project_id)
        }
      }
    } catch ({ message }) {
      commit(UPDATE_MESSAGE, { type: "error", message })
    }

    /*
    * The admin re-authenticated with custom token, immediately when is logged in
    * */
    const admin_token = rootState[ADMIN][USER]?.user_info?.token;
    if (admin_token) await window.firebase.auth().signInWithCustomToken(admin_token)

    if (start_loader) commit(UPDATE_GLOBAL_LOADER, false, { root: true })
  },
  async [CLIENT_LOGIN]({ commit, state }, {
    name, is_registration, success_message, password_again, password,
    token, email, language
  }) {
    commit(UPDATE_GLOBAL_LOADER, true, { root: true })
    let provider, result
    commit(UPDATE_MESSAGE, { type: "error", message: "" })

    if (name === social_logins[0]) provider = new window.firebase.auth.GoogleAuthProvider();
    else if (name === social_logins[1]) provider = new window.firebase.auth.FacebookAuthProvider();

    try {
      if (is_registration && !name) {
        if (password_again !== password) {
          commit(UPDATE_GLOBAL_LOADER, false, { root: true })
          return commit(UPDATE_MESSAGE, { type: "error", message: "Passwords do not match." })
        }
        result = await window.firebase.auth().createUserWithEmailAndPassword(email, password)
        await window.firebase.auth().currentUser.sendEmailVerification(action_code_settings(state.is_global_admin))
        await create_new_user({
          user_data: {
            user_info: {
              name: result.user.displayName,
              email: result.user.email,
              country: "",
              language_code: language,
              phone_number: result.user.phoneNumber,
              uid: result.user.uid,
            }
          },
          is_basic_user: true,
          is_admin_user: false
        })

        commit(UPDATE_GLOBAL_LOADER, false, { root: true })
        return commit(UPDATE_MESSAGE, {
          type: "success",
          message: success_message
        })
      }
      else if (name) result = await window.firebase.auth().signInWithPopup(provider)
      else if (token) result = await window.firebase.auth().signInWithCustomToken(token)
      else result = await window.firebase.auth().signInWithEmailAndPassword(email, password)

    } catch ({ message }) {
      commit(UPDATE_GLOBAL_LOADER, false, { root: true })
      return commit(UPDATE_MESSAGE, { type: "error", message })
    }

    const user = {
      user_info: {
        name: result.user.displayName,
        email: result.user.email,
        country: "",
        language_code: language,
        phone_number: result.user.phoneNumber,
        uid: result.user.uid,
      }
    }

    if (result.additionalUserInfo.isNewUser && name) await create_new_user({
      user_data: user, is_basic_user: true, is_admin_user: false
    })

    if (is_registration && !name) commit(UPDATE_MESSAGE, {
      type: "success",
      message: success_message
    })

    const { data: { info, ...rest } } = await fetch_user_profile({ user_id: user.user_info.uid })

    commit(UPDATE_GLOBAL_USER, { ...rest, info: { ...info, uid: user.user_info.uid } }, { root: true })
    commit(UPDATE_GLOBAL_LOADER, false, { root: true })
  },
  async [LOG_OUT_USER]({ commit }, { route }) {
    commit(UPDATE_GLOBAL_LOADER, true, { root: true })

    try {
      document.cookie = `${user_cookie}=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=${window.location.host};`

      await window.firebase.auth().signOut()
      commit(`${USER_STORE}/${RESET_USER_STORE}`, undefined, { root: true })
      commit(UPDATE_USER_CREDENTIALS, null)
      commit(UPDATE_GLOBAL_USER, null, { root: true })

      routerInstance.push(route)
    } catch (error) {
      console.log(error)
    }

    commit(UPDATE_GLOBAL_LOADER, false, { root: true })
  },
  async [VERIFY_AND_SET_DISCOUNT]({ commit, rootState }, [discount_code, selected_group_language, selected_group]) {
    commit(UPDATE_GLOBAL_LOADER, true, { root: true })

    const { data } = await verify_discount_code({
      discount_code,
      basket_items: selected_group || rootState[CLIENT_STORE].basket_items,
      selected_language: selected_group_language || rootState[CLIENT_STORE].selected_language,
      group_project_id: selected_group && selected_group[0].project_id
    })

    commit(UPDATE_DISCOUNT_CODE, data)
    commit(UPDATE_GLOBAL_LOADER, false, { root: true })
  },
  [CHANGE_QUANTITY_OF_PRODUCT]({ commit }, item) {
    if (item.quantity === 0) commit([DELETE_BASKET_ITEM], item)
    else commit([CHANGE_QUANTITY_OF_PRODUCT], item)
  },
  async [SET_WHOLE_BASKET]({ commit, rootState }, basket_data) {
    commit(UPDATE_BASKET_LOADER, true)

    basket_data = basket_data || []

    if (basket_data?.length) {
      if (rootState[CLIENT_STORE].use_shopify_data) basket_data = await shopify_product_availability_updater(basket_data)
      else {
        const { data } = await validate_basket(adjust_basket_data_to_not_include_duplicates(basket_data))
        basket_data = data
      }
    }

    commit(SET_WHOLE_BASKET, basket_data)
    commit(UPDATE_BASKET_LOADER, false)

    localStorage.setItem(
      user_basket_items,
      JSON.stringify(basket_data)
    );
  },
  async [VALIDATE_BASKET]({ state, commit, rootState }) {
    commit(UPDATE_BASKET_LOADER, true)

    let updated_basket_items = state.basket_items

    if (state.basket_items?.length) {
      if (rootState[CLIENT_STORE].use_shopify_data) updated_basket_items = await shopify_product_availability_updater(state.basket_items)
      else {
        const { data } = await validate_basket(adjust_basket_data_to_not_include_duplicates(state.basket_items))
        updated_basket_items = data
      }
    }

    commit(SET_WHOLE_BASKET, updated_basket_items)
    commit(UPDATE_BASKET_LOADER, false)
  },
  async [GET_CURRENCY_RATES]({ commit }, currency) {
    if (rates_being_fetched[currency]) return new Promise((resolve) => {
      const interval = setInterval(() => {
        if (!rates_being_fetched[currency]) {
          clearInterval(interval)
          resolve()
        }
      }, 200);
    })

    rates_being_fetched[currency] = true
    const { data } = await get_rates_for_currency({ currency })

    commit(UPDATE_CURRENCY_RATES, [currency, data])
    rates_being_fetched[currency] = false
  }
}
