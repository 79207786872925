import {
  SET_PERMISSIONS, SET_USER_INFO, SET_MANAGED_USERS, RESET_USER_STORE, base_state,
  SET_USER_AND_PROJCT_CREADENTIALS, SET_USER_PROJECTS, SET_USER_SELECTED_PROJECT,
  USER_STORE_TOUR_TOGGLE, SET_GENERAL_ADMIN_STATE
} from "./constants";

export default {
  [SET_MANAGED_USERS](state, managed_users) {
    state.managed_users = managed_users
  },
  [SET_PERMISSIONS](state, permissions) {
    state.permissions = permissions
  },
  [SET_USER_INFO](state, user_info) {
    state.user_info = user_info
  },
  [SET_USER_AND_PROJCT_CREADENTIALS](state, credentials) {
    state.credentials = credentials
  },
  [SET_USER_PROJECTS](state, projects) {
    state.projects = projects
  },
  [SET_USER_SELECTED_PROJECT](state, selected_project = "") {
    state.selected_project = selected_project
  },
  [RESET_USER_STORE](state) {
    Object.assign(state, base_state())
  },
  [USER_STORE_TOUR_TOGGLE](state, { value, type }) {
    state[`showing_${type}_tour`] = value
  },
  [SET_GENERAL_ADMIN_STATE](state, { is_any_other_store_admin, is_any_store_admin }) {
    state.is_any_store_admin = is_any_store_admin
    state.is_any_other_store_admin = is_any_other_store_admin
  },
}
