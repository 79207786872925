export const PAYPAL = "paypal"
export const SHOPIFY = "shopify"
export const ADMIN = "admin"
export const CLIENT = "client"
export const STORES = "stores"
export const TEST = "test"
export const MARKETPLACE = "marketplace"
export const AUTHORIZATION = "auth"
export const GUIDE = "guide"
export const BILLING = "billing"
