<template>
  <div class="loader-wave" :class="`loader-wave--${size}`">
    <span
      class="loader-wave__circle"
      v-for="color in colors"
      :key="color"
      :class="`loader-wave__circle--${size}`"
      :style="{ background: color }"
    />
  </div>
</template>

<script>
import {loader_sizes} from "."

export default {
  props: {
    size: {
      type: String,
      required: false,
      default: loader_sizes.base,
    }
  },
  data() {
    return {
      colors: ["#cc4b4e", "#333333", "#3498db", "#1abc9c"],
    }
  }
}
</script>

<style lang="scss">
.loader-wave {
  display: flex;
  align-items: center;

  &__circle {
    border-radius: 50%;
    animation: wave-animation 1s ease-in-out infinite;
    animation-delay: 0.2s;

    &:nth-child(2) {
      animation-delay: 0.3s;
    }
    &:nth-child(3) {
      animation-delay: 0.4s;
    }
    &:nth-child(4) {
      animation-delay: 0.5s;
      margin-right: 0;
    }

    &--base {
      width: 30px !important;
      height: 30px !important;
      margin-right: 5px !important;
    }
    &--small {
      animation: wave-animation--small 1s ease-in-out infinite;
      width: 20px !important;
      height: 20px !important;
      margin-right: 3px !important;
    }
    &--large {
      width: 40px !important;
      height: 40px !important;
      margin-right: 7px !important;
    }
  }

  @keyframes wave-animation {
    0% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-20px);
    }
    70% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(0);
    }
  }

  @keyframes wave-animation--small {
    0% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-10px);
    }
    70% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(0);
    }
  }
}
</style>
