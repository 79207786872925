import { CONFIGURATION, CONTENT, FINANCE, PRODUCTS, SOCIAL, USER_ROUTE } from "../../applications/Admin/constants/others_constants"
import { ADMIN, BILLING, GUIDE } from "../page_types"

// Parents
const Products = () => import("../../applications/Admin/components/content/products.vue")
const Configuration = () => import("../../applications/Admin/components/content/configuration.vue")
const Website_content = () => import("../../applications/Admin/components/content/website_content.vue")
const Finance = () => import("../../applications/Admin/components/content/finance.vue")
const User = () => import("../../applications/Admin/components/content/user.vue")
const Social = () => import("../../applications/Admin/components/content/social.vue")
const Admin = () => import("../../applications/Admin/components/routes/admin.vue")
const Store_setup = () => import("../../applications/Admin/components/content/store_setup.vue")
const Marketplace_setup = () => import("../../applications/Admin/components/content/marketplace_setup.vue")

export const category_types = {
  products: "products",
  content: "content",
  configuration: "configuration",
  finance: "finance",
  user: "user",
  social: "social"
}

export default [
  {
    path: "/:store_id?",
    alias: [
      `/${ADMIN}/:store_id?`,
      "/Admin/:store_id?"
    ],
    name: ADMIN,
    component: Admin,
    children: [
      {
        path: `${category_types.products}/:page_type`,
        components: {
          "admin_content": Products
        },
        name: PRODUCTS
      },
      {
        path: `${category_types.content}/:content_type`,
        components: {
          "admin_content": Website_content
        },
        name: CONTENT
      },
      {
        path: `${category_types.configuration}/:configuration_type`,
        components: {
          "admin_content": Configuration
        },
        name: CONFIGURATION
      },
      {
        path: `${category_types.finance}/:finance_type`,
        components: {
          "admin_content": Finance
        },
        name: FINANCE
      },
      {
        path: `${category_types.user}/:user_action_type`,
        components: {
          "admin_content": User
        },
        name: USER_ROUTE
      },
      {
        path: `${category_types.social}/:social_action_type`,
        components: {
          "admin_content": Social
        },
        name: SOCIAL
      },
      {
        path: "store_setup",
        name: "store_setup",
        components: {
          "admin_content": Store_setup
        }
      },
      {
        path: "marketplace_setup",
        name: "marketplace_setup",
        components: {
          "admin_content": Marketplace_setup
        }
      },
      ...Object.values(category_types).map(type => ({
        path: `/${ADMIN}/${type}`,
        alias: `/Admin/${type}`,
        redirect: `/${ADMIN}`
      })),
      {
        path: `/${GUIDE}`,
        name: GUIDE,
        components: {
          "other_admin_components": () => import("../../applications/Admin/components/utils/user_guide/guide.vue")
        },
        children: [
          ...Array.from({ length: 8 }, (_, i) => i + 1).map(step_number => ({
            path: `step_${step_number}`,
            name: step_number,
            components: {
              "guide_content": () => import(`../../applications/Admin/components/utils/user_guide/step_${step_number === 7 ? 6 : step_number}.vue`)
            }
          }))
        ]
      },
      {
        path: `/${BILLING}/:store_id`,
        name: BILLING,
        components: {
          other_admin_components: () => import("../../applications/Admin/components/utils/billing.vue")
        },
      }
    ]
  }
]
