import {
  ADD_TO_STORE, ASSIGN_DATA_TO_STORE, FETCH_SHOPIFY_DATA,
  UPDATE_LOADER, UPDATE_DATA_LOAD_STATE, FETCH_FIREBASE_DATA,
  FETCH_SHOPIFY_FINANCIALS, FETCH_IMPORTED_FINANCIALS,
  UPDATE_PROJECT_STATE, LOGIN_VIA_TOKEN, FETCH_USER_CURRENCY_RATES, CLEAR_STORE,
  CLEAR_ADMIN_STORES, FETCH_AND_ASSING_MOMENT_LIBRARY,
  SET_GLOBAL_MOMENT_LIBRARY_VARIABLE, BUILD_NEW_STORE, SET_LOADING_MESSAGE,
  FETCH_SUBSCRIPTION_STATUS
} from "./constants"
import { shopify, imported, PRODUCTS, CONTENT, CONFIGURATION, FINANCE, USER, USER_STORE, SOCIAL, ADMIN } from "../../constants/others_constants"
import { fetch_firebase_data } from "../../../Shared/methods/ajax/endpoints"
import { get_shopify_financial_data, fetch_admin_shopify_data } from "../../constants/endpoints/shopify"
import {
  add_user_projects_and_permissions, create_new_store, fetch_financials, fetch_subscription_data, get_user_projects, update_website_configuration
} from "../../constants/endpoints/firebase"
import { get_rates_for_currency } from "../../constants/endpoints/other"
import { GET_USER_CURRENCY, SET_USER_PROJECTS } from "./user/constants"
import { ADMIN as ADMIN_PAGE } from "../../../../data/page_types"
import moment from "moment"
import project_styles from "../../../../data/project_styles"
import upload_image from "../../methods/upload_image"
import { default_language } from "../../../Shared/constants/other"
import empty_collection_banner from "../../constants/empty_objects/empty_collection_banner"
import { translate } from "../../../Shared/translator"

export default {
  [ASSIGN_DATA_TO_STORE]({ commit }, data = {}) {
    Object.entries(data).forEach(([ key, value ]) => {
      // For Products data
      if (
        [
          "collections",
          "collects",
          "products",
        ].some(val => `${shopify}_${val}` === key || `${imported}_${val}` === key) ||
        key === "inventory_checks"
      ) commit(ADD_TO_STORE, [PRODUCTS, key, value])
      // For Products data
      else if (
        key.includes("header") ||
        key.includes("footer") ||
        key.includes("banners") ||
        key.includes("popular_products") ||
        key.includes("highlighted_products") ||
        key.includes("important_message_bar") ||
        key.includes("about_page_content")
      ) commit(ADD_TO_STORE, [CONTENT, key, value])
      // For Website configuration
      else if (
        key.includes("translations") ||
        key.includes("single_product_homepages") ||
        key.includes("couple_products_homepages") ||
        key.includes("project_styles") ||
        key.includes("rest_config") ||
        key.includes("shopify_config") ||
        key.includes("shipping_options") ||
        key.includes("analytics_config") ||
        key.includes("project_config") ||
        key.includes("paypal_config") ||
        key.includes("other_page_translations") ||
        key.includes("project_name")
      ) commit(ADD_TO_STORE, [CONFIGURATION, key, value])
      // For Financial data
      else if ([
        `${shopify}_orders`,
        `${imported}_orders`,
        `${shopify}_locations`
      ].includes(key)) commit(ADD_TO_STORE, [FINANCE, key, value])
      // For User data
      else if (
        ["paying_user", "user_accounts", "plan_type"].includes(key)
      ) commit(ADD_TO_STORE, [USER, key, value])
      // Rest
      else commit(ADD_TO_STORE, ["", key, value])
    })
  },
  async [FETCH_SUBSCRIPTION_STATUS]({ dispatch }) {
    const { data } = await fetch_subscription_data()

    dispatch(ASSIGN_DATA_TO_STORE, data)
  },
  async [FETCH_FIREBASE_DATA]({ dispatch, commit }, should_handle_loading = true) {
    if (should_handle_loading) commit(UPDATE_LOADER, true)

    const { data } = await fetch_firebase_data(ADMIN_PAGE)

    dispatch(ASSIGN_DATA_TO_STORE, data)
    commit(UPDATE_DATA_LOAD_STATE, true)
    if (should_handle_loading) commit(UPDATE_LOADER, false)
  },
  async [FETCH_SHOPIFY_DATA]({ rootState, dispatch }) {
    if (!rootState[ADMIN][CONFIGURATION].shopify_config.should_use_shopify_data) return

    const { data: { collections, collects, products} } = await fetch_admin_shopify_data()

    dispatch(ASSIGN_DATA_TO_STORE, {
      shopify_collections: collections,
      shopify_collects: collects,
      shopify_products: products
    })
  },
  async [FETCH_SHOPIFY_FINANCIALS]({ commit }) {
    const { data } = await get_shopify_financial_data()

    Object.entries(data).forEach(([key, val]) => commit(ADD_TO_STORE, [FINANCE, key, val]))
  },
  async [FETCH_IMPORTED_FINANCIALS]({ commit }) {
    const { data } = await fetch_financials()

    Object.entries(data).forEach(([key, val]) => commit(ADD_TO_STORE, [FINANCE, key, val]))
  },
  [UPDATE_PROJECT_STATE]() {
    update_website_configuration("project_config", false, "new_project")
  },
  async [LOGIN_VIA_TOKEN]({ state }) {
    if (
      window.firebase.auth().currentUser &&
      window.firebase.auth().currentUser.providerData[0].providerId === "google.com" // Check if user logged into Firebase
    ) return

    // Log in user using existing token
    await window.firebase.auth().signInWithCustomToken(state[USER]?.user_info?.token)
  },
  async [FETCH_USER_CURRENCY_RATES]({ commit, rootGetters }, currency) {
    const { data } = await get_rates_for_currency({ currency: currency || rootGetters[`${USER_STORE}/${GET_USER_CURRENCY}`] })

    commit(ADD_TO_STORE, [USER, "rates_info", data])
  },
  [CLEAR_ADMIN_STORES]({ commit }) {
    commit(CLEAR_STORE, PRODUCTS)
    commit(CLEAR_STORE, CONTENT)
    commit(CLEAR_STORE, CONFIGURATION)
    commit(CLEAR_STORE, FINANCE)
    commit(CLEAR_STORE, SOCIAL)
  },
  async [FETCH_AND_ASSING_MOMENT_LIBRARY]({ commit, state }) {
    if (state.$moment) return

    commit(SET_GLOBAL_MOMENT_LIBRARY_VARIABLE, moment)
  },
  async [BUILD_NEW_STORE](
    { commit, state },
    {
      store_name: new_store_name = "", new_store_domain = "", store_languages = [], product_data,
      collection_banners = [], popular_products = [], highlighted_products = [], menu_items = [],
      store_colors, store_logo
    }
  ) {
    commit(UPDATE_LOADER, true)
    commit(`${ADMIN}/${SET_LOADING_MESSAGE}`, await translate("loading_messages.creating_store"), { root: true })

    const uploading_product_images = product_data?.products[0].images ? 
      product_data.products[0].images.map(async (image, index) => {
        product_data.products[0].images[index].change_type = undefined // Set change type

        if (!image.file) return

        const final_value = await upload_image(
          image,
          image.route,
          false,
        )

        product_data.products[0].images[index].src = final_value
      }) : []
    const uploading_collection_image = async () => {
      const image = product_data.collections?.[0]?.image

      if (image && image.file) product_data.collections[0].image = await upload_image(
        image,
        image.route,
        false,
        false,
        false,
        true
      )
    }
    const uploading_collection_banners_images = collection_banners?.length ?
      collection_banners.map(async ({ image_object, img }, index) => {
        let final_value = { mobile: img }

        // If uploading own image
        if (image_object) {
          final_value = await upload_image(
            image_object,
            `project_images/collection_banners/${image_object.file.name}`,
            false,
            true,
            false,
            false,
            true
          )
        }

        collection_banners[index] = empty_collection_banner(
          collection_banners[index].category,
          collection_banners[index].sub_category,
          final_value.mobile,
          final_value.mobile,
          null,
          collection_banners[index].button_text,
          collection_banners[index].description,
          collection_banners[index].title,
        )
      }) : []
    const uploading_logo_image = async () => {
      if (store_logo) store_logo = await upload_image(
        store_logo,
        "project_images/logo",
        false,
        true,
        false,
        false,
        true
      )
    }

    try {
      if (product_data?.products[0]) {
        commit(`${ADMIN}/${SET_LOADING_MESSAGE}`, await translate("loading_messages.uploading_store_images"), { root: true })
        await Promise.all([
          ...uploading_product_images,
          ...uploading_collection_banners_images,
          uploading_collection_image(),
          uploading_logo_image(),
        ])

        // Update language translations for each store language
        store_languages.forEach(async (language) => {
          product_data.products[0].translations[language] = product_data.products[0].translations[default_language]
          product_data.products[0].variants[0].translations[language] = product_data.products[0].variants[0].translations[default_language]
          menu_items = menu_items?.map((item) => {
            item.translations[language] = item.translations[default_language]
  
            return item
          })
        })
  
        if (!store_languages.includes(default_language)) {
          delete product_data.products[0].translations[default_language]
          delete product_data.products[0].variants[0].translations[default_language]
        }
      }

      const { data: {
        new_store_id,
        store_name_id,
        store_domain,
        store_name
      } } = await create_new_store({
        user_id: state[USER].user_info.id,
        selected_plan: "",
        project_name: new_store_name,
        project_domain: new_store_domain,
        user_name: state[USER].user_info.name,
        email: state[USER].user_info.email,
        languages: store_languages,
        is_new_user: true,
        product_data,
        collection_banners,
        popular_products: popular_products?.length && popular_products.map(({ id }) => id),
        highlighted_products: highlighted_products?.length && highlighted_products.map(({ id }) => id),
        header_items: menu_items,
        project_styles: {
          colors: {
            ...project_styles.colors,
            ...store_colors
          },
          sizes: project_styles.sizes,
        },
        ...(store_logo && { rest_config: {
          favicon: store_logo,
          icon: store_logo,
          splash_image_color: "red"
        }})
      })

      commit(`${ADMIN}/${SET_LOADING_MESSAGE}`, await translate("loading_messages.finalizing_store_creation"), { root: true })
      await add_user_projects_and_permissions({
        user_id: state[USER].user_info.id,
        store_id: new_store_id
      })

      const { data: user_projects } = await get_user_projects({ user_id: state[USER].user_info.id })

      commit(`${USER_STORE}/${SET_USER_PROJECTS}`, user_projects, { root: true })

      commit(UPDATE_LOADER, false)
      commit(`${ADMIN}/${SET_LOADING_MESSAGE}`, "", { root: true })

      return {
        new_store_id,
        store_domain,
        store_name_id,
        store_name
      }
    } catch ({ message }) {
      commit(UPDATE_LOADER, false)
      console.log(message)

      return {}
    }
  }
}
