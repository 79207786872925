import { api } from "../../../Shared/methods/ajax/axios_instance"
import {
  CAN_MANAGE_PRODUCTS, CAN_MANAGE_LAYOUT_AND_CONTENT, CAN_SEE_MODULE_CONFIGURATION,
  CAN_SEE_MODULE_CONFIGURATION_SHOPIFY, CAN_SEE_MODULE_FINANCE, CAN_MANAGE_MODULE_FINANCE_ORDERS,
  CAN_SEE_MODULE_PRODUCTS, CAN_SEE_MODULE_LAYOUT_AND_CONTENT, CAN_SEE_MODULE_CONFIGURATION_PAYMENT_GATEWAY,
  CAN_SEE_MODULE_USER_PERMISSIONS, CAN_MANAGE_MODULE_USER_PERMISSIONS, IS_ADMIN, CAN_MANAGE_MODULE_CONFIGURATION_PROJECT_CONFIGURATION,
  CAN_MANAGE_MODULE_FINANCE_DISCOUNTS, CAN_MANAGE_MODULE_SOCIAL_MEDIA_ACCOUNTS, CAN_SEE_MODULE_SOCIAL_MEDIA,
  CAN_SEE_MODULE_MARKETING_ANALYTICS_ADS,
  CAN_SEE_MODULE_FINANCE_ORDERS
} from "../permissions"

/**
 * Products
 */

  // Update product
  export const update_product = (product_index, product, delete_product) => api.post("update_imported_product", {
    product_index,
    product,
    delete_product
  }, { headers: { permission_key: CAN_MANAGE_PRODUCTS } })

  // Restock variant in product and collection product
  export const restock_imported_product = ({ product_id, variant_id, quantity }) => api.post(
    "restock_imported_product",
    { product_id, variant_id, quantity },
    { headers: { permission_key: CAN_MANAGE_MODULE_FINANCE_ORDERS }}
  )

/**
 * Collections
 */

  // Update collections
  export const update_collection = (collection_index, collection, delete_collection) => api.post("update_imported_collection", {
    collection_index,
    collection,
    delete_collection
  }, { headers: { permission_key: CAN_MANAGE_PRODUCTS } })

/**
 * Inventory checks
 */

  // Update Inventory checks
  export const update_inventory_checks = inventory_checks => api.post(
    "update_inventory_checks", { inventory_checks }, { headers: { permission_key: CAN_MANAGE_PRODUCTS } }
  )

/**
 * Content
 */

  // Update content
  export const update_content = (type, payload) => api.post("update_content", {
    type,
    payload
  }, { headers: { permission_key: CAN_MANAGE_LAYOUT_AND_CONTENT } })

/**
 * Website Configuration
 */

  // Update website configuration
  export const update_website_configuration = (type, payload, key) => api.post("update_configuration", {
    type,
    payload,
    key
  }, { headers: { permission_key: CAN_SEE_MODULE_CONFIGURATION } })

  export const update_project_domains = ({ domain }) => api.post("update_project_domains", {
    domain
  }, { headers: { permission_key: CAN_MANAGE_MODULE_CONFIGURATION_PROJECT_CONFIGURATION } })


  export const verify_domain_uniqueness = domain => api.post("verify_domain_uniqueness", {
    domain
  })

/**
 * Shopify Configuration
 */

  // Check if access to Shopify is correct
  export const check_shopify_API_access = shopify_config => api.post("check_shopify_API_access", { shopify_config },
  { headers: { permission_key: CAN_SEE_MODULE_CONFIGURATION_SHOPIFY } })


/**
 * Financials
 */

  // Check if access to Shopify is correct
  export const fetch_financials = () => api.get("fetch_financials", { headers: { permission_key: CAN_SEE_MODULE_FINANCE } })

  // Update order
  export const update_imported_order = (order, state_type) => api.post(
    "update_imported_order", { order, state_type }, { headers: { permission_key: CAN_MANAGE_MODULE_FINANCE_ORDERS } }
  )

  // Refund order
  export const refund_ulsemo_order = ({
    order_id,
    capture_id,
    payload = {},
    customer_locale,
    order_currency,
    cross_selling_store_id,
    cross_product_profit_share,
    order_number,
    payment_method
  }) => api.post(
    "refund_ulsemo_order",
    {
      order_id,
      capture_id,
      payload,
      customer_locale,
      order_currency,
      cross_selling_store_id,
      cross_product_profit_share,
      order_number,
      payment_method
    },
    { headers: { permission_key: CAN_MANAGE_MODULE_FINANCE_ORDERS } }
  )

  // Create/Update discount
  export const update_discount_codes = (code, price_rule) => api.post(
    "update_discount_codes", { code, price_rule }, { headers: { permission_key: CAN_MANAGE_MODULE_FINANCE_DISCOUNTS } }
  )

  // Basic financial overview
  export const get_basic_financial_overview = user_currency => api.post("get_basic_financial_overview", { user_currency })

  // Latest orders
  export const latest_orders = duration => api.post(
    "latest_orders",
    { duration },
    { headers: { permission_key: CAN_SEE_MODULE_FINANCE_ORDERS } }
  )

  // Get pagination-able, sortable and searchable orders
  export const get_orders = ({ limit, page, search_keys, statuses, sort_type, sort_direction }) => api.post(
    "get_orders",
    { limit, page, search_keys, statuses, sort_type, sort_direction },
    { headers: { permission_key: CAN_SEE_MODULE_FINANCE_ORDERS }
  })


/**
 * Admin login
 */
  export const login_to_admin = (email, password, current_project_id, user_id) =>
    api.post("admin_login", { email, password, current_project_id, user_id })


/**
 * Admin verify user token
 */
  export const verify_user_token = async ({ user_token }) => await api.post("verify_user_token", { user_token })


/**
 * Image edit
 */
  export const store_and_update_image = (form_data) => api.post(
    "store_and_update_image",
    form_data,
    { headers: { "Content-Type": "multipart/form-data" } }
  )

/**
 * Paypal connection
 */
  export const check_paypal_connection_api = client_id => api.post(
    "check_paypal_connection", { client_id }, { headers: { permission_key: CAN_SEE_MODULE_CONFIGURATION_PAYMENT_GATEWAY }}
  )

/**
 * Fetch individual section data
 */
  export const fetch_website_content = () => api.post(
    "fetch_website_content", { headers: { permission_key: CAN_SEE_MODULE_LAYOUT_AND_CONTENT }}
  )
  export const fetch_website_configuration = () => api.post(
    "fetch_website_configuration", { headers: { permission_key: CAN_SEE_MODULE_CONFIGURATION }}
  )
  export const fetch_products_data = () => api.post(
    "fetch_products_data", { headers: { permission_key: CAN_SEE_MODULE_PRODUCTS }}
  )

/**
 * User endpoints
 */
  export const get_users_info = (user_ids, uid) => api.post(
    "get_users_info", { user_ids, uid }, { headers: { permission_key: CAN_SEE_MODULE_USER_PERMISSIONS }}
  )

  export const create_new_user = ({ user_data, is_basic_user }) => api.post(
    "create_new_user", { user_data, is_basic_user }, { headers: { permission_key: CAN_MANAGE_MODULE_USER_PERMISSIONS }}
  )

  export const assign_managed_users = ({ managed_users, user_id }) => api.post(
    "assign_managed_users", { managed_users, user_id }, { headers: { permission_key: CAN_MANAGE_MODULE_USER_PERMISSIONS }}
  )

  export const update_user_permissions_and_managed_users = ({ managed_users, permissions, id }) => api.post(
    "update_user_permissions_and_managed_users",
    { managed_users, permissions, id },
    { headers: { permission_key: CAN_MANAGE_MODULE_USER_PERMISSIONS }}
  )

  export const update_user_info = ({ user_info, id }) => api.post(
    "update_user_info",
    { user_info, id }
  )

  export const delete_user = ({ user_id }) => api.post(
    "delete_user",
    { user_id },
    { headers: { permission_key: CAN_MANAGE_MODULE_USER_PERMISSIONS }}
  )

  export const get_user_projects = ({ user_id }) => api.post(
    "get_user_projects",
    { user_id }
  )

  export const find_user_by_email = (email) => api.post(
    "find_user_by_email",
    { email },
    { headers: { permission_key: CAN_MANAGE_MODULE_USER_PERMISSIONS }}
  )

/**
 * Store creation endpoint
 * Creates a new store under user ID
 */

  export const create_new_store = ({
    user_id, selected_plan, project_name, project_domain, user_name, email, languages, is_new_user,
    ...rest
  }) => api.post("create_new_store", {
    user_id, selected_plan, project_name, project_domain, user_name, email, languages, is_new_user,
    ...rest
  }, { headers: { permission_key: IS_ADMIN }})
  
  export const add_user_projects_and_permissions = ({ user_id, store_id, user, permissions }) => api.post(
    "add_user_projects_and_permissions",
    { user_id, store_id, user, permissions },
    { headers: { permission_key: user ? CAN_MANAGE_MODULE_USER_PERMISSIONS : IS_ADMIN }}
  )

/**
 * Endpoint for creating and assigning payments
 */
  export const create_project_payment = ({ payment_data, type }) => api.post(
    "create_project_payment",
    { payment_data, type },
    { headers: { permission_key: CAN_MANAGE_MODULE_FINANCE_ORDERS } }
  )

/**
 * Support message mailer
 */
  export const send_support_message = ({ to, subject, text, html, from }) => api.post(
    "send_support_message",
    { to, subject, text, html, from }
  )

/**
 * Social media
 */
  export const fetch_social = fetch_analytics_config => api.post(
    "fetch_social",
    { fetch_analytics_config },
    { headers: { permission_key: CAN_SEE_MODULE_SOCIAL_MEDIA } }
  )

  export const add_projects_social_entities = ({ entities, key }) => api.post(
    "add_projects_social_entities",
    { entities, key },
    { headers: { permission_key: CAN_MANAGE_MODULE_SOCIAL_MEDIA_ACCOUNTS } }
  )

  export const fetch_country_latlon = countries => api.post(
    "fetch_country_latlon",
    { countries },
    { headers: { permission_key: CAN_SEE_MODULE_MARKETING_ANALYTICS_ADS } }
  )

/**
 * Store and user account deletion
 */
  export const delete_store_or_user = ({ uid, deleting_only_user }) => api.post(
    "delete_store_or_user",
    { uid, deleting_only_user },
    { headers: { permission_key: IS_ADMIN } }
  )

/**
 * Subscription
 */

  // Fetch user subscription data
  export const fetch_subscription_data = () => api.post("fetch_subscription_data")
